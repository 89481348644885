import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from 'src/components/Button/Button'
import { Container } from 'src/components/Container/Container'
import { Headline } from 'src/components/Headline/Headline'
import { Text } from 'src/components/Text/Text'

import styles from './NoContent.module.scss'

import type Error from 'next/error'

export const NoContent = ({ error }: { error: Error & { digest?: string } }) => {
  const { t } = useTranslation()
  const { pathname } = useRouter()
  const title = t('common.error404.title')
  const text = t('common.error404.text')
  const buttonCTA = t('common.error404.buttonCTA')

  useEffect(() => {
    if (pathname !== '/404') {
      ;(async () => {
        const { getCurrentScope } = await import('@sentry/browser')
        const scope = getCurrentScope?.()
        if (scope?.getClient()) {
          scope.captureException(error)
        } else {
          // eslint-disable-next-line no-console
          console.warn('Sentry client not initialized; skipping capture.')
        }
      })()
    }
  }, [error])

  return (
    <div className={styles.wrapper}>
      <Container className={styles.container} wide>
        <Headline variant="h1">{title}</Headline>
        <Text variant="p">{text}</Text>
        <Button variant="secondary" href="/">
          {buttonCTA}
        </Button>
      </Container>
    </div>
  )
}
